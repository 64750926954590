import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

import { AppLanguage, appLanguages, useLangStore } from '@/store/lang-store.ts';

import { Button } from '@/components/ui/button.tsx';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu.tsx';


interface LocaleSwitcherProps {
}

export const LocaleSwitcher: FC<LocaleSwitcherProps> = () => {
  const {
    i18n: { changeLanguage, language }
  } = useTranslation();

  const { currentLang, setCurrentLang } = useLangStore();
  const otherLocales = appLanguages?.filter((locale) => locale !== currentLang);

  const handleLangChange = async (lang: AppLanguage) => {
    setCurrentLang(lang);
    await changeLanguage(lang);
  };

  useEffect(() => {
    setCurrentLang(language as AppLanguage);
  }, [language, setCurrentLang]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="invisible" className={'flex gap-2'}>
          {currentLang.toUpperCase()}
          <ReactCountryFlag
            countryCode={currentLang === 'en' ? 'gb' : currentLang}
            svg
            style={{
              width: '1.5rem',
              height: '1.2rem'
            }}
            className={'border-accent3 border'}
            title={currentLang}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className={'grid grid-cols-1 gap-2'}>
          {otherLocales?.map((locale, index) => {
            return (
              <DropdownMenuItem
                key={`locale-${index}-${locale}`}
              >
                <Button
                  variant="invisible"
                  onClick={async () => {
                    await handleLangChange(locale);

                  }}
                >
                  <div className={'flex justify-between gap-4'}>
                    {locale.toUpperCase()}
                    <ReactCountryFlag
                      countryCode={locale === 'en' ? 'gb' : locale}
                      svg
                      style={{
                        width: '1.5rem',
                        height: '1.2rem'
                      }}
                      className={'border-accent3 border'}
                      title={locale}
                    />
                  </div>
                </Button>
              </DropdownMenuItem>
            );
          })}
        </div>
      </DropdownMenuContent>

    </DropdownMenu>
  );
};
