import { FC, ReactNode, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { LoadingSpinner } from "@/components/shared/LoadingSpinner.tsx";

import { useCheckoutGetOrder } from "@/hooks/service-hooks/klarna/use-klarna.ts";

import { useCheckoutDataStore } from "@/store/checkout-store.ts";

interface OrderPageProps {
  children: ReactNode;
}

export const OrderPage: FC<OrderPageProps> = (props) => {
  const { children } = props;

  const { orderID } = useParams<{ orderID: string }>();
  const { setCheckoutData, checkoutData } = useCheckoutDataStore();
  const getOrder = useCheckoutGetOrder();

  useEffect(() => {
    if (!orderID) {
      return;
    }

    if (getOrder.isPending) {
      return;
    }

    if (getOrder.isSuccess) {
      return;
    }

    if (getOrder.isError) {
      return;
    }

    getOrder.mutate({ orderID });
  }, [getOrder, orderID]);

  useEffect(() => {
    if (!getOrder.data) {
      return;
    }

    if (checkoutData?.order_id !== orderID || !checkoutData) {
      setCheckoutData(getOrder.data);
    }

    if (checkoutData?.order_id === orderID) {
      return;
    }
  }, [getOrder.data, checkoutData, setCheckoutData, orderID]);

  return (
    <div className={"min-h-[80vh] "}>

      {!checkoutData && getOrder.isPending && (
        <div className={"w-full h-96 flex justify-center items-center"}>
          <LoadingSpinner />
        </div>
      )}

      {getOrder.isError && <div>Error loading order</div>}

      {checkoutData && children}
    </div>
  );
};
