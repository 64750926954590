import { FC } from "react";

import { Badge } from "@/components/ui/badge.tsx";

import { MerchantData } from "@/core/entities";

interface CapsulesConfigProps {
  capsulesConfig: MerchantData["capsulesConfig"];
}

export const CapsulesConfig: FC<CapsulesConfigProps> = ({ capsulesConfig }) => {
  if (!capsulesConfig) {
    return null;
  }

  return (
    <div className={"flex flex-wrap gap-1"}>
      {capsulesConfig.espresso > 0 && (
        <Badge variant={"outline"}>Espresso - {capsulesConfig.espresso}</Badge>
      )}

      {capsulesConfig.lungo > 0 && (
        <Badge variant={"outline"}>Lungo - {capsulesConfig.lungo}</Badge>
      )}

      {capsulesConfig.ristretto > 0 && (
        <Badge variant={"outline"}>
          Ristretto - {capsulesConfig.ristretto}
        </Badge>
      )}

      {capsulesConfig.decaf > 0 && (
        <Badge variant={"outline"}>Decaf - {capsulesConfig.decaf}</Badge>
      )}
    </div>
  );
};
