import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button.tsx";

export type ErrorPageError = {
  statusText: string;
  message: string;
};

interface ErrorPageProps {
  error: ErrorPageError;
}
export const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  const navigation = useNavigate();

  return (
    <div className="flex h-full overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-background">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
              <div className="text-center px-4">
                {/*<div className="inline-flex text-4xl items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 mb-4">*/}
                {/*TODO: ADD WARNING ICON*/}
                {/*<Icons.warning />*/}
                {/*</div>*/}
                <div className="mb-6">{error.statusText}</div>
                <p className={"mb-6"}>
                  {error.message}
                  <br />
                </p>

                <Button onClick={() => navigation(-1)}>Go Back</Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
