import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Icons } from "@/components/ui/icons.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useCheckoutDataStore } from "@/store/checkout-store.ts";
import { KlarnaGetCheckoutOrderResponse } from "@/core/entities/klarna-get-checkout-order-response.ts";

export const CheckoutConfirmationPage: FC = () => {
  const { t } = useTranslation("translation");
  const { checkoutData } = useCheckoutDataStore();

  useEffect(() => {
    if (!checkoutData) {
      return;
    }

    if (!checkoutData.html_snippet) {
      return;
    }

    const boxElement = document.getElementById("checkout-div");

    if (boxElement) {
      boxElement.innerHTML = checkoutData.html_snippet
        .replace(/\\"/g, '"')
        .replace(/\\n/g, "");

      const scriptsTags = boxElement.getElementsByTagName("script");

      for (let i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode;
        const newScriptTag = document.createElement("script");
        newScriptTag.type = "text/javascript";
        newScriptTag.text = scriptsTags[i].text;
        parentNode?.removeChild(scriptsTags[i]);
        parentNode?.appendChild(newScriptTag);
      }

      try {
        const value = Number((checkoutData.order_amount / 100).toFixed(2));
        // Track Initiate checkout Facebook
        // eslint-disable-next-line
        // @ts-ignore
        fbq("trackCustom", "Purchase", {
          value,
          currency: "SEK",
        });

        // Track Initiate checkout TikTok
        //eslint-disable-next-line
        // @ts-ignore
        // ttq.track("CompletePayment");

        const pintrkLineItems = checkoutData.order_lines.map((item, index) => ({
          product_name: item.name,
          product_id: item.name + index,
          product_price: Number((item.unit_price / 100).toFixed(2)),
          product_quantity: item.quantity,
        }));

        // Track visited page Pinterest
        // eslint-disable-next-line
        // @ts-ignore
        pintrk("track", "checkout", {
          currency: "SEK",
          line_items: pintrkLineItems,
        });

        const pintrkData = {
          value,
          currency: "SEK",
          order_quantity: "1",
          line_items: pintrkLineItems,
        };

        // Track added to card Pinterest
        // eslint-disable-next-line
        // @ts-ignore
        pintrk("track", "addtocart", pintrkData);
      } catch (e) {
        console.log("FB Initial error", e);
      }

      // Addrevenue
      try {
        const valueWithoutVAT = Number(
          (checkoutData.order_amount / 1.12).toFixed(2),
        );

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ADDREVENUE.sendEvent("Purchase", {
          value: valueWithoutVAT,
          currency: checkoutData.purchase_currency || "SEK",
          orderId: checkoutData.order_id,
        });
      } catch (error) {
        if (error instanceof Error) {
          console.log("Addrevenue error", {
            error,
            errorMessage: error.message,
          });
        }
      }
    }
  }, [checkoutData]);

  useEffect(() => {
    if (!checkoutData) {
      return;
    }

    if (!checkoutData.html_snippet) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    const eventPrefix = "nl_";
    const dataSchema = {
      ecommerce: {
        show: true,
      },
      dynamicRemarketing: {
        show: true,
        business_vertical: "retail",
      },
    };

    try {
      const orderData =
        checkoutData as unknown as KlarnaGetCheckoutOrderResponse;

      const currentUser = {
        first_name: orderData.billing_address.given_name,
        last_name: orderData.billing_address.family_name,
        email: orderData.billing_address.email,
        phone: orderData.billing_address.phone,
        address: {
          address1: orderData.billing_address.street_address,
          address2: orderData.billing_address.street_address2,
          street: orderData.billing_address.street_name,
          city: orderData.billing_address.city,
          zip_code: orderData.billing_address.postal_code,
          country: orderData.billing_address.country,
          country_code: orderData.purchase_country,
          province: orderData.billing_address.region,
          company: orderData.customer.vat_id || "",
        },
      };

      window.dataLayer.push({
        customer: currentUser,
      });
    } catch (e) {
      console.log("Error in setting customer data", e);
    }

    try {
      const couponCode = [];
      if (checkoutData.merchant_reference2) {
        couponCode.push(checkoutData.merchant_reference2);
      }

      // eslint-disable-next-line
      const dataLayerData: Record<string, any> = {
        event: eventPrefix + "purchase",
        ecommerce: {
          transaction_id: checkoutData.order_id,
          value: checkoutData.order_amount / 100,
          tax: checkoutData.order_tax_amount / 100,
          shipping: 0, //checkoutData.shipping_options[0].price / 100 || 0,
          discount: 0, //checkoutData.order_discount / 100 || 0
          currency: checkoutData.purchase_currency,
          coupon: couponCode,
          items: checkoutData.order_lines.map((line_item) => {
            let merchantData: {
              shopifyProductID: string;
              shopifyVariantID: string;
            } | null = null;

            try {
              if (line_item.merchant_data) {
                merchantData = JSON.parse(line_item.merchant_data);
              }
            } catch (e) {
              merchantData = null;
            }

            return {
              item_id: merchantData?.shopifyVariantID || "",
              product_id: merchantData?.shopifyProductID || "",
              variant_id: merchantData?.shopifyVariantID || "",
              item_name: line_item.name,
              discount: line_item.total_discount_amount / 100,
              price: line_item.total_amount / 100,
              quantity: line_item.quantity,
              // item_variant: "",
              // sku: "",
              // item_brand: "",
              // item_category: "",
            };
          }),
        },
        dynamicRemarketing: undefined,
      };

      if (dataSchema.dynamicRemarketing && dataSchema.dynamicRemarketing.show) {
        dataLayerData.dynamicRemarketing = {
          value: dataLayerData.ecommerce.value,
          // eslint-disable-next-line
          items: dataLayerData.ecommerce.items.map((item: any) => ({
            id: item.item_id,
            google_business_vertical:
              dataSchema.dynamicRemarketing.business_vertical,
          })),
        };
      }

      if (!dataSchema.ecommerce || !dataSchema.ecommerce.show) {
        delete dataLayerData["ecommerce"];
      }

      window.dataLayer.push(dataLayerData);
    } catch (e) {
      console.log("Error in setting purchase data", e);
    }
  }, [checkoutData]);

  return (
    <div className={"space-y-8 pt-24 sm:pt-32"}>
      <div className={"flex items-center justify-center"}>
        <Icons.orderComplete className={"w-32 h-32"} />
      </div>

      <h1
        className={
          "text-3xl leading-9 tracking-wide text-center font-bold text-foreground"
        }
      >
        {t("thankYouForYourPurchase")}
        {checkoutData && (
          <div>
            {checkoutData.merchant_data
              ?.toString()
              .includes("subscriptionID") && (
              <>
                {t("changePaymentMessage")}

                <br />
              </>
            )}

            {!checkoutData.merchant_data
              ?.toString()
              .includes("subscriptionID") && (
              <>
                {t("packingYourProduct")}
                <br />
              </>
            )}
          </div>
        )}
      </h1>

      <section className={"text-center text-foreground italic"}>
        <CustomerAccountButton />
      </section>
    </div>
  );
};

const CustomerAccountButton: FC = () => {
  const { t } = useTranslation("translation");

  const accountPageUrl = "https://coffydoor.com/account";

  return (
    <Button asChild>
      <a href={accountPageUrl}>{t("goToYourAccount")}</a>
    </Button>
  );
};
