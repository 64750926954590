export default {
  translation: {
    fastDelivery: "Fast delivery",
    fastDeliveryDescription:
      "Home delivery all over Sweden in 24-48 hours on weekdays excluding weekends\n",
    favouriteCoffeeTaste: "Favourite coffee taste",
    favourite: "Favourite",
    favouriteDescription: "The favorite coffee of over 10,000 Swedes",
    yourOrder: "Your order",
    theCapsulesAreShippedToday: "The capsules are shipped today",
    shipEveryWeek: "Ship every week",
    showOrder: "Show order",
    hideOrder: "Hide order",
    enterCode: "Enter code",
    free: "Free",
    delivery: "Delivery",
    subTotal: "Subtotal",
    total: "Total",
    incVat: "incl. VAT",
    changePaymentMessage:'Your payment method is now updated and you will receive your delivery soon',
    addUpsellProduct: "Add upsell product",
    subscription: "Subscription",
    oneTime: "One-time",
    bundle: "Bundle",
    "two-weeks": "Subscription (every 2 weeks)",
    "four-weeks": "Subscription (every 4 weeks)",
    "six-weeks": "Subscription (every 6 weeks)",
    "eight-weeks": "Subscription (every 8 weeks)",
    "twelve-weeks": "Subscription (every 12 weeks)",
    nextDeliveryInfo1: "Then",
    nextDeliveryInfo2:
      "or select desired frequency on my pages after your purchase.",
    useAGiftCardOrDiscountCode: "Use a gift card or discount code",
    enterYourDiscountCodeHere: "Enter your discount code here",
    use: "Use",
    nextOrder: "Next order",
    yourNextOrderWillBeShippedOn: "Your next order will be shipped on",
    andThePriceWillbeChangedTo: "and the price will be changed to",
    ifNotSatisfiedWithYourTestPackage:
      "If you are not satisfied with your test package, you can cancel your future deliveries in your Coffydoor account.",
    changeBlendsAndQuantities: "Change blends and quantities whenever you want",
    chooseYourselfTheFrequency:
      "Choose yourself how often you want to receive coffee",
    tasteGuaranteeAndYourMoneyBack: "Taste guarantee and your money back",

    success: "Success",
    discountCodeIsApplied: "Discount code is applied",
    discountCodeRemovedSuccessfully: "Discount code removed successfully",
    failedToApplyDiscountCode: "Failed to apply discount code",
    error: "Error",
    anErrorOccurredWhileApplyingTheDiscountCode:
      "An error occurred while applying the discount code",
    anErrorOccurredWhileRemovingTheDiscountCode:
      "An error occurred while removing the discount code",

    additionalItems: "Additional items",
    shippingAddress: "Shipping address",
    summary: "Summary",
    discount: "Discount",
    usedPoints: "Used points",
    applyYourDiscountCode: "Apply Your Discount code",
    yourDiscountCodeIs: "Your discount code is",
    remove: "Remove",
    apply: "Apply",
    code: "Code",

    thankYouForYourPurchase: "Thank you for your purchase!",
    goToYourAccount: "Go to your account",
    whyOver: "WHY OVER 25,000 CUSTOMERS ENJOY COFFYDOOR",
    easyRefunds: "Easy Refunds & Returns",
    refundsInMonth:
      "30 day money-back guarantee if you don’t get the experience you expected.",
    joinMovement: "Join the Movement",
    overCustomers:
      "Over 25,000 customers already converted and joined the mission to enjoy the best coffee experience.",
    fourUniqueFlavors: "4 Unique Coffee Blends",
    eachBlend:
      "Espresso, Ristretto, Lungo, and Decaf. Each blend is crafted to deliver a unique experience.",
    tasting: "Delicious Tasting",
    systemToDelivery:
      "Innovative flavor systems to deliver the perfect espresso, ristretto, lungo, and decaf experiences.",
    packingYourProduct:
      "Now we hurry down to the warehouse and pack your order",
  },
};
