import { httpsCallable } from "@firebase/functions";
import { firebase } from "@/infrastructure";
import {
  CheckoutAddDiscountCodePayload,
  CheckoutAddProductPayload,
  CheckoutRemoveDiscountCodePayload,
  CheckoutRemoveProductPayload,
  CheckoutUpdateProductPayload,
  BackendService,
} from "@/core/ports/services/backend-service.ts";
import { KlarnaGetOrderResponse } from "@/core/entities";

export const backendService: BackendService = {
  async checkoutAddDiscountCode(payload) {
    await httpsCallable<CheckoutAddDiscountCodePayload, void>(
      firebase.functions,
      "v2CheckoutAddDiscountCode",
    )(payload);
  },
  async checkoutAddProduct(payload) {
    await httpsCallable<CheckoutAddProductPayload, void>(
      firebase.functions,
      "v2CheckoutAddProduct",
    )(payload);
  },
  async checkoutRemoveDiscountCode(payload) {
    await httpsCallable<CheckoutRemoveDiscountCodePayload, void>(
      firebase.functions,
      "v2CheckoutRemoveDiscountCode",
    )(payload);
  },
  async checkoutRemoveProduct(payload) {
    await httpsCallable<CheckoutRemoveProductPayload, void>(
      firebase.functions,
      "v2CheckoutRemoveProduct",
    )(payload);
  },
  async checkoutUpdateProduct(payload) {
    await httpsCallable<CheckoutUpdateProductPayload, void>(
      firebase.functions,
      "v2CheckoutUpdateProduct",
    )(payload);
  },

  async checkoutGetOrder(payload) {
    const response = await httpsCallable<
      { orderID: string },
      KlarnaGetOrderResponse
    >(
      firebase.functions,
      "v2CheckoutGetOrder",
    )(payload);

    return response.data;
  },
};
