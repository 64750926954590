import { httpsCallable } from '@firebase/functions';
import { firebase } from '@/infrastructure';

import {
  ApplyDiscountCodeCheckoutPayload,
  DiscountCodeService,
  RemoveDiscountCodeCheckoutPayload,
  UpdateKlarnaOrderResponse
} from '@/core/ports/services/discount-code-service.ts';

export const discountCodeService: DiscountCodeService = {
  async applyDiscountCode (payload) {
    const result = await httpsCallable<ApplyDiscountCodeCheckoutPayload, UpdateKlarnaOrderResponse>(
      firebase.functions,
      'v2CheckoutAddDiscountCode'
    )(payload);

    return result.data;
  },
  async removeDiscountCode (payload) {
    const result = await httpsCallable<RemoveDiscountCodeCheckoutPayload, UpdateKlarnaOrderResponse>(
      firebase.functions,
      'v2CheckoutRemoveDiscountCode'
    )(payload);

    return result.data;
  }
};
