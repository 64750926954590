import { FC } from "react";
import { Icons } from "@/components/ui/icons.tsx";

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const currenYear = new Date().getFullYear();
  const brandName = "Coffydoor";
  return (
    <footer className={"bg-background pt-12 pb-2 sm:pb-12"}>
      <div className={"flex justify-center gap-6 mt-10"}>
        <div className={"text-black w-5 h-5"}>
          <Icons.klarna />
        </div>
        <div className={" text-black w-5 h-5"}>
          <Icons.paypal />
        </div>
        <div className={" text-black w-5 h-5"}>
          <Icons.visa />
        </div>
        <div className={" text-black w-5 h-5"}>
          <Icons.masterCard />
        </div>
        <div className={" text-black h-5 w-5"}>
          <Icons.amex />
        </div>
      </div>
      <div
        className={
          "grid text-center text-black sm:flex justify-center gap-6 mt-4"
        }
      >
        <p className={"text-muted-foreground text-xs"}>
          &copy; {currenYear} {brandName}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
