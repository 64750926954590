// import * as VFX from "react-vfx";
import { FC } from "react";

interface TextEffectProps {
  text: string;
}
export const TextEffectHalftone: FC<TextEffectProps> = ({ text }) => (
  // <VFX.VFXSpan className={"my-2 py-1"} shader="hueShift">
  //   {text}
  // </VFX.VFXSpan>
  <span className={"my-2 py-1"}>{text}</span>
);
