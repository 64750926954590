import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum AppLanguage {
  SE = "se",
  EN = "en",
}
export const appLanguages: AppLanguage[] = [
  AppLanguage.SE,
  AppLanguage.EN,
];

export interface LangStore {
  currentLang: AppLanguage;
  setCurrentLang: (lang: AppLanguage) => void;
}

export const useLangStore = create<LangStore>()(
  persist(
    (set) => ({
      currentLang: AppLanguage.EN,
      setCurrentLang: (currentLang) =>
        set((state: LangStore) => ({
          ...state,
          currentLang,
        })),
    }),
    {
      name: "coffydoor-customer-portal-language-store"
    }
  )
);
