import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DiscountData, partialDiscountSchema } from '@/core/entities/forms/discout.ts';

export function useDiscountForm(props: UseFormProps<DiscountData>) {
  return useForm<DiscountData>({
    ...props,
    resolver: zodResolver(partialDiscountSchema),
  });
}
