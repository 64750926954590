import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "@/components/shared/header.tsx";
import { Footer } from "@/components/shared/footer.tsx";
import { Toaster } from '@/components/ui/toaster.tsx';

export const RootLayout: FC = () => {
  return (
    <div>
      <div
        className={
          "max-w-7xl mx-auto p-4 flex flex-col min-h-screen h-full justify-between "
        }
      >
        <Header />
        <div className={"flex-grow"}>
          <Outlet />
        </div>
        <Toaster />
        <Footer />
      </div>
    </div>
  );
};
