import { create } from "zustand";
import { KlarnaGetOrderResponse } from "@/core/entities";

export interface LangStore {
  checkoutData: KlarnaGetOrderResponse | null;
  setCheckoutData: (checkoutData: KlarnaGetOrderResponse ) => void;
}

export const useCheckoutDataStore = create<LangStore>()(
  // persist(
  (set) => ({
    checkoutData: null,
    setCheckoutData: (checkoutData) =>
      set((state: LangStore) => ({
        ...state,
        checkoutData,
      })),
  }),
  //   {
  //     name: "klarna-checkout-store",
  //   },
  // ),
);
