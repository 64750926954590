// import * as VFX from "react-vfx";
import { FC } from "react";

interface TextEffectProps {
  text: string;
}

export const TextEffect: FC<TextEffectProps> = ({ text }) => (
  // <VFX.VFXDiv
  //   className={"text-muted-foreground font-light text-base my-2 py-1"}
  //   shader="warpTransition"
  // >
  //   {text}
  // </VFX.VFXDiv>
  <div className={"text-muted-foreground font-light text-base my-2 py-1"}>
    {text}
  </div>
);
