import { serviceHost } from "@/services";
import { useMutation } from "@tanstack/react-query";
import {
  CheckoutAddDiscountCodePayload,
  CheckoutAddProductPayload,
  CheckoutRemoveDiscountCodePayload,
  CheckoutRemoveProductPayload,
  CheckoutUpdateProductPayload,
} from "@/core/ports/services/backend-service.ts";

const backendService = serviceHost.getBackendService();

export const useCheckoutAddDiscountCode = () => {
  return useMutation({
    mutationKey: ["checkoutAddDiscountCode"],
    mutationFn: (payload: CheckoutAddDiscountCodePayload) =>
      backendService.checkoutAddDiscountCode(payload),
  });
};

export const useCheckoutAddProduct = () => {
  return useMutation({
    mutationKey: ["checkoutAddProduct"],
    mutationFn: (payload: CheckoutAddProductPayload) =>
      backendService.checkoutAddProduct(payload),
  });
};

export const useCheckoutRemoveDiscountCode = () => {
  return useMutation({
    mutationKey: ["checkoutRemoveDiscountCode"],
    mutationFn: (payload: CheckoutRemoveDiscountCodePayload) =>
      backendService.checkoutRemoveDiscountCode(payload),
  });
};

export const useCheckoutRemoveProduct = () => {
  return useMutation({
    mutationKey: ["checkoutRemoveProduct"],
    mutationFn: (payload: CheckoutRemoveProductPayload) =>
      backendService.checkoutRemoveProduct(payload),
  });
};

export const useCheckoutUpdateProduct = () => {
  return useMutation({
    mutationKey: ["checkoutUpdateProduct"],
    mutationFn: (payload: CheckoutUpdateProductPayload) =>
      backendService.checkoutUpdateProduct(payload),
  });
};

export const useCheckoutGetOrder = () => {
  return useMutation({
    mutationKey: ["checkoutGetOrder"],
    mutationFn: ({ orderID }: { orderID: string }) =>
      backendService.checkoutGetOrder({
        orderID,
      }),
  });
};
