import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { TextEffect } from '@/components/shared/TextEffect.tsx';
import { TextEffectHalftone } from '@/components/shared/TextEffectHalftone.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card.tsx';

import { cn } from '@/lib/utils.ts';

interface FeaturesComponentProps extends HTMLAttributes<HTMLDivElement> {
}

export const FeaturesComponent: FC<FeaturesComponentProps> = (props) => {
  const { t } = useTranslation("translation");

  return (
    <Card
      className={cn(
        props.className,
        'lg:max-w-xl mx-auto p-6'
      )}
    >
      <CardHeader>
        <CardTitle className="text-2xl tracking-wide font-bold text-center mb-6">
          <TextEffectHalftone text={t("whyOver")} />
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-8">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">📦</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              <TextEffectHalftone text={t("easyRefunds")} />
            </h3>
            <TextEffect text={t("refundsInMonth")} />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">🤎</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              <TextEffectHalftone text={t("joinMovement")} />
            </h3>
            <TextEffect text={t("overCustomers")} />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">☕</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              <TextEffectHalftone text={t("fourUniqueFlavors")} />
            </h3>

            <TextEffect text={t("eachBlend")} />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">👅</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              <TextEffectHalftone text={t("tasting")} />
            </h3>
            <TextEffect text={t("systemToDelivery")} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
