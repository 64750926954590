import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/components/ui/icons.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card.tsx';

import { ProductItem } from '@/pages/checkout/components/product-item.tsx';

import { formatDate } from '@/utils/format-date.ts';
import { formatPriceNormal } from '@/utils/format-price.ts';
import { getNextOrderData } from '@/utils/next-order-date.ts';

import { ChangeToProduct, MerchantData } from '@/core/entities';

interface NextOrderInfoProps extends HTMLAttributes<HTMLDivElement> {
  changeToProduct: ChangeToProduct;
  merchantData: MerchantData;
}

export const NextOrderInfo: FC<NextOrderInfoProps> = (props) => {
  const { changeToProduct: product, merchantData } = props;
  const { t, i18n } = useTranslation("translation");

  const nextOrderPrice = product?.price;

  const nextOrderDate = getNextOrderData(merchantData);
  if (!nextOrderDate) {
    return null;
  }

  if (!product) {
    return null;
  }

  if (!nextOrderPrice) {
    return null;
  }

  return (
    <Card className={props.className}>
      <CardHeader>
        <CardTitle className={"capitalize"}>{t("nextOrder")}</CardTitle>
        <CardDescription className={"mt-4 items-center gap-1"}>
          {t("yourNextOrderWillBeShippedOn")}
          <span
            className={
              "font-bold text-background px-1 mx-1 bg-primary p-0.5 rounded-lg animate-pulse"
            }
          >
            {formatDate(nextOrderDate, i18n.language)}
          </span>
          {t('andThePriceWillbeChangedTo') + ' '}
          {formatPriceNormal(nextOrderPrice)}
          {". "}<br/>
          {t('nextDeliveryInfo1')}
          <span
            className={
              "font-bold text-background px-1 mx-1 bg-primary p-0.5 rounded-lg animate-pulse"
            }
          >
            {t(
              merchantData.isTestPackage
                ? "four-weeks"
                : merchantData.subscriptionInterval || "",
            )}
          </span>
          {t("nextDeliveryInfo2")}
        </CardDescription>
      </CardHeader>

      <CardContent>
        <ProductItem
          product={{
            image: product.image,
            name: product.name,
            quantity: 1,
            price: formatPriceNormal(product.price),
            merchantData: {
              ...merchantData,
              capsulesConfig:
                merchantData.changeToProduct?.capsulesConfig ||
                merchantData.capsulesConfig,
            },
          }}
        />

        <Separator className={"my-6"} />

        <div>
          <CardDescription>
            {t("ifNotSatisfiedWithYourTestPackage")}
          </CardDescription>
        </div>

        <Separator className={"my-6"} />

        <div className={"flex flex-col"}>
          <div className={"flex justify-start gap-4"}>
            <Icons.check />
            <CardDescription>{t("changeBlendsAndQuantities")}</CardDescription>
          </div>

          <div className={"flex justify-start gap-4"}>
            <Icons.check />
            <CardDescription>{t("chooseYourselfTheFrequency")}</CardDescription>
          </div>

          <div className={"flex justify-start gap-4"}>
            <Icons.check />
            <CardDescription>
              {t("tasteGuaranteeAndYourMoneyBack")}
            </CardDescription>
          </div>
        </div>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
};
