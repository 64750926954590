import { z } from 'zod';

export const discountSchema = z.object({
  code: z.string()
});

export const partialDiscountSchema = discountSchema.partial();

export type PartialDiscountData = z.infer<typeof partialDiscountSchema>;

export type DiscountData = z.infer<typeof discountSchema>;

