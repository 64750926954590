export const RoutePath = {
  HOME: "/",
  LOAD_ORDER: "/order/:orderID",
  CONFIRMATION: "/confirmation/:orderID",
};

export const OuterPath = {
  TEST_PACKAGE_URL: "https://coffydoor.com/products/coffydoor-testpaket",
  HOME_PAGE_URL: 'https://coffydoor.com/'
};
