import { useMutation, useQueryClient } from '@tanstack/react-query';
import { serviceHost } from '@/services';
import {
  ApplyDiscountCodeCheckoutPayload,
  RemoveDiscountCodeCheckoutPayload
} from '@/core/ports/services/discount-code-service.ts';

const discountService = serviceHost.getDiscountCodeService();

export const useApplyDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['discount-codes', 'mutation', 'apply'],
    mutationFn: (payload: ApplyDiscountCodeCheckoutPayload) => {
      return discountService.applyDiscountCode(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['discount-codes']
      });
    }
  });
};

export const useRemoveDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['discount-codes', 'mutation', 'remove'],
    mutationFn: (payload: RemoveDiscountCodeCheckoutPayload) => {
      return discountService.removeDiscountCode(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['discount-codes']
      });
    }
  });
};
