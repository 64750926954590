export default {
  translation: {
    fastDelivery: "Snabb leverans",
    fastDeliveryDescription:
      // "Hemleverans över hela Sverige på 24-48h helgfria vardagar",
      "Hemleverans på 24-48h helgfria vardagar",
    favouriteCoffeeTaste: "Favoritsmak",
    favourite: "Nöjdhetsgaranti ",
    favouriteDescription: "Över 25 000 svenskars favoritkaffe",
    yourOrder: "Din order",
    theCapsulesAreShippedToday: "Kapslarna skickas idag",
    shipEveryWeek: "Skicka varje vecka",
    delivery: "Leverans",
    enterCode: "Ange kod",
    free: "Gratis",
    showOrder: 'Visa orderöversikt',
    hideOrder: 'Dölj orderöversikt',
    subTotal: "Delsumma",
    total: "Totalt",
    incVat: "inkl. moms",
    addUpsellProduct: "Lägg till extra produkt",
    subscription: "Prenumeration",
    oneTime: "Engångs",
    bundle: "Paket",
    "two-weeks": "Leverans var 2a vecka",
    "four-weeks": "Leverans var 4e vecka",
    "six-weeks": "Leverans var 6e vecka",
    "eight-weeks": "Leverans var 8e vecka",
    "twelve-weeks": "Leverans var 12e vecka",
    nextDeliveryInfo1: "Därefter",
    nextDeliveryInfo2:
      "eller välj önskad frekvens på mina sidor efter ditt köp.",
    useAGiftCardOrDiscountCode: "Använd presentkort eller rabattkod",
    enterYourDiscountCodeHere: "Skriv in din kod här",
    applyYourDiscountCode: "Använd din rabattkod",
    yourDiscountCodeIs: "Din rabattkod är",
    remove: "Ta bort",
    apply: "Använd",
    use: "Använd",
    nextOrder: "Nästa order",
    yourNextOrderWillBeShippedOn: "Din nästa order skickas",
    andThePriceWillbeChangedTo: "och priset ändras till",
    ifNotSatisfiedWithYourTestPackage:
      "Om du inte är nöjd med ditt testpaket kan du avbryta dina framtida leveranser i ditt Coffydoor-konto.",
    changeBlendsAndQuantities: "Ändra blandningar och kvantiteter när du vill",
    chooseYourselfTheFrequency: "Välj själv hur ofta du vill få kaffe",
    tasteGuaranteeAndYourMoneyBack: "Smakgaranti och pengarna tillbaka",

    additionalItems: "Extra produkter",
    shippingAddress: "Leveransadress",
    summary: "Summering",
    discount: "Rabatt",
    usedPoints: "Intjänade poäng",

    success: "Framgång",
    discountCodeIsApplied: "Rabattkod har tillämpats",
    discountCodeRemovedSuccessfully: "Rabattkod borttagen framgångsrikt",
    failedToApplyDiscountCode: "Det gick inte att tillämpa rabattkoden",
    error: "Fel",
    anErrorOccurredWhileApplyingTheDiscountCode:
      "Ett fel uppstod när rabattkoden tillämpades",
    anErrorOccurredWhileRemovingTheDiscountCode:
      "Ett fel uppstod när rabattkoden togs bort",
    changePaymentMessage: 'Din betalmetod är nu uppdaterad och du kommer snart att få din leverans',
    thankYouForYourPurchase:
      "Tack för din beställning och grattis till uppgraderade mornar framöver!",
    code: "Kod",
    goToYourAccount: "Gå till ditt konto",
    whyOver: "Varför över 25 000 kunder har njutit av Coffydoor",
    packingYourProduct: "Nu skyndar vi oss ned på lagret och packar din order",
    easyRefunds: "En vardagslyx du förtjänar",
    refundsInMonth:
      "Med hemleverans över hela Sverige och gratis sådan för medlemmar. Uppgraderar Coffydoor din morgon där du befinner dig.",
    joinMovement: "Var med på resan",
    overCustomers:
      "Över 25 000 kunder har redan gått över till Coffydoor i jakten på den bästa starten på dagen.",
    fourUniqueFlavors: "Fyra unika smaker",
    eachBlend:
      "Lungo, Espresso, Ristretto & Decaf. Varje blend anpassad efter skandinaviska smakpreferenser och erbjuder det där lilla extra.",
    tasting: "Överraskande gott",
    systemToDelivery:
      "Njut av en fyllig crema, intensiva aromer och en välbalanserad kropp.\n" +
      "Coffydoor erbjuder smakgaranti på samtliga ordrar.",
  },
};
