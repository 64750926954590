import "./instrument";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import * as VFX from "react-vfx";

import "./global.css";
import "./i18next.ts";

import router from "./routes";

import { ThemeProvider } from "@/components/theme-provider.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <VFX.VFXProvider>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <RouterProvider router={router} />
        </ThemeProvider>
      </VFX.VFXProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}
