import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from '@/components/shared/error-page.tsx';
import { RoutePath } from '@/routes/constants/route-paths.ts';
import { RootLayout } from '@/components/shared/root.tsx';
import { OrderPage } from '@/pages/load-order/order-page.tsx';
import { CheckoutConfirmationPage } from '@/pages/confirmation/checkout-confirmation-page.tsx';
import { CheckoutSection } from '@/pages/checkout/checkout-section.tsx';

export default createBrowserRouter([
  {
    path: RoutePath.HOME,
    element: <RootLayout />,
    errorElement: (
      <ErrorPage
        error={{
          statusText: "Subscription schedules not found",
          message: "The subscription you are looking for does not exist",
        }}
      />
    ),
    children: [
      {
        path: RoutePath.LOAD_ORDER,
        element: <OrderPage
          children={<CheckoutSection/>}
        />,
        errorElement: (
          <ErrorPage
            error={{
              statusText: "Load Order Error",
              message: "There was an error loading the order",
            }}
          />
        ),
      },
      {
        path: RoutePath.CONFIRMATION,
        element: <OrderPage
          children={<CheckoutConfirmationPage/>}
        />,
        errorElement: (
          <ErrorPage
            error={{
              statusText: "Subscription schedules not found",
              message: "The subscription you are looking for does not exist",
            }}
          />
        ),
      },
    ],
  },
]);
