import { FC } from 'react';
import { Icons } from '@/components/ui/icons.tsx';
import { ModeToggle } from '@/components/mode-toggle.tsx';
import { LocaleSwitcher } from '@/components/shared/lang-toggle.tsx';
import { Link } from 'react-router-dom';
import { OuterPath } from '@/routes/constants/route-paths.ts';

interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  return (
    <div className={"w-full"}>
      <div className={"flex justify-between w-full"}>
        <div className={"w-12"}>
          <LocaleSwitcher />
        </div>

        <div className={"flex items-center"}>
          <Link
            to={OuterPath.HOME_PAGE_URL}
            target={'_blank'}
          >
            <Icons.logo/>
          </Link>
        </div>

        <div className={"flex justify-end w-12"}>
          <ModeToggle />
        </div>
      </div>
    </div>
  );
};
