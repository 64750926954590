import {
  initializeApp,
  getApps,
  FirebaseApp,
  FirebaseOptions,
} from "@firebase/app";

import { initializeAuth, browserLocalPersistence, Auth } from "@firebase/auth";
import { getDatabase, Database } from "@firebase/database";
import { getFirestore, Firestore } from "@firebase/firestore";
import { getFunctions, Functions } from "@firebase/functions";
import { getStorage, FirebaseStorage } from "@firebase/storage";

const firebaseConfig: FirebaseOptions = {
  // PRODUCTION
  apiKey: "AIzaSyCooycUwSnaqSLT3m6yC_B0j_gvykXrEow",
  authDomain: "coffydoor-55d2f.firebaseapp.com",
  projectId: "coffydoor-55d2f",
  storageBucket: "coffydoor-55d2f.appspot.com",
  messagingSenderId: "15552948267",
  appId: "1:15552948267:web:91e6bcecffb349ce0ca26e",

  // DEVELOPMENT
  // apiKey: "AIzaSyB9M_z2rS4IhTzwnSo-E3J4tBq634PMBCk",
  // authDomain: "coffydoor-development.firebaseapp.com",
  // projectId: "coffydoor-development",
  // storageBucket: "coffydoor-development.appspot.com",
  // messagingSenderId: "891372939678",
  // appId: "1:891372939678:web:9013486dbc2b8a3eb04e8f",
  // measurementId: "G-W1K7W63MCK"
};

const initializeFirebaseApp = (): FirebaseApp => {
  let app: FirebaseApp;

  const apps = getApps();

  if (apps.length === 0) {
    app = initializeApp(firebaseConfig);
  } else {
    app = apps[0];
  }

  return app;
};

const app = initializeFirebaseApp();

console.log("Firebase app initialized", app);

export type Firebase = {
  app: FirebaseApp;
  auth: Auth;
  database: Database;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
};

export const firebase: Firebase = {
  app,
  auth: initializeAuth(app, {
    persistence: browserLocalPersistence,
  }),
  database: getDatabase(app),
  firestore: getFirestore(app),
  functions: getFunctions(app),
  storage: getStorage(app),
};

export const projectId = firebaseConfig.projectId;
