export const formatPrice = (
  price: number,
  locale: string = "sv-SE",
  currency: string = "SEK",
) => {
  const priceFormat = price / 100;
  let formattedPrice = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(priceFormat);

  if (formattedPrice.includes("kr")) {
    return formattedPrice;
  }

  formattedPrice = formattedPrice.replace("SEK", "") + " kr";

  return formattedPrice;
};

export const formatPriceNormal = (price: number, locale: string = "sv-SE") => {
  let formattedPrice = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "SEK",
  }).format(price);

  if (formattedPrice.includes("kr")) {
    return formattedPrice;
  }

  // Replace "SEK" with "kr" in the formatted price string
  formattedPrice = formattedPrice.replace("SEK", "") + " kr";

  return formattedPrice;
};
