import { useTranslation } from 'react-i18next';
import { FC, HTMLAttributes, useCallback, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { useApplyDiscountCode, useRemoveDiscountCode } from '@/hooks/service-hooks/discount-code/use-discount-code.ts';
import { Button } from '@/components/ui/button.tsx';
import { useToast } from '@/components/ui/use-toast.ts';
import { useParams } from 'react-router-dom';
import { cn } from '@/lib/utils.ts';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { useDiscountForm } from '@/hooks/forms/entities/use-discount-form.ts';
import { DiscountData } from '@/core/entities/forms/discout.ts';
import { LoadingSpinner } from '@/components/shared/LoadingSpinner.tsx';
import { useCheckoutDataStore } from '@/store/checkout-store.ts';
import { KlarnaGetOrderResponse } from '@/core/entities';

interface DiscountCodeProps extends HTMLAttributes<HTMLDivElement>{
}

export const DiscountCode: FC<DiscountCodeProps> = (props) => {
  const { orderID } = useParams<{ orderID: string }>();
  const { t } = useTranslation('translation');

  const { toast } = useToast();

  const { setCheckoutData, checkoutData } = useCheckoutDataStore();

  const removeDiscountCode = useRemoveDiscountCode();
  const applyDiscountCode = useApplyDiscountCode();

  const form = useDiscountForm({
    defaultValues: {
      code: ''
    },
    mode: 'onChange'
  });

  const onApplyCodeSubmit = useCallback((data: DiscountData) => {
    if (!orderID) {
      console.log('orderID is missing');
      return;
    }

    applyDiscountCode.mutate({
      orderID,
      discountCode: data.code
    });
  }, [applyDiscountCode, orderID]);

  const onRemoveCodeSubmit = useCallback(() => {
    if (!orderID) {
      console.log('orderID is missing');
      return;
    }

    if(!checkoutData) {
      console.log('checkoutData is missing');
      return;
    }

    if(!checkoutData.merchant_reference2) {
      console.log('checkoutData?.merchant_reference2 is missing');
      return;
    }

    removeDiscountCode.mutate({
      orderID: orderID,
      discountCode: checkoutData.merchant_reference2
    });
  }, [checkoutData, orderID, removeDiscountCode]);


  useEffect(() => {
    if (applyDiscountCode.isPending) {
      return;
    }

    if (applyDiscountCode.isError) {
      return;
    }

    if (!applyDiscountCode.isSuccess) {
      return;
    }

    if (!applyDiscountCode.data) {
      return;
    }

    const data: KlarnaGetOrderResponse = applyDiscountCode.data as unknown as KlarnaGetOrderResponse;

    setCheckoutData(data);
  }, [applyDiscountCode.data, applyDiscountCode.isError, applyDiscountCode.isPending, applyDiscountCode.isSuccess, setCheckoutData]);

  useEffect(() => {
    if (applyDiscountCode.isSuccess) {
      toast({
        title: t('success'),
        description: t('discountCodeIsApplied')
      });

      return;
    }

    if (applyDiscountCode.isError) {
      toast({
        title: t('error'),
        description: t('anErrorOccurredWhileApplyingTheDiscountCode')
      });

      return;
    }
  }, [applyDiscountCode.isError, applyDiscountCode.isSuccess, t, toast]);

  useEffect(() => {
    if (removeDiscountCode.isPending) {
      return;
    }

    if (removeDiscountCode.isError) {
      return;
    }

    if (!removeDiscountCode.isSuccess) {
      return;
    }

    if (!removeDiscountCode.data) {
      return;
    }

    const data: KlarnaGetOrderResponse = removeDiscountCode.data as unknown as KlarnaGetOrderResponse;

    setCheckoutData(data);
  }, [removeDiscountCode.data, removeDiscountCode.isError, removeDiscountCode.isPending, removeDiscountCode.isSuccess, setCheckoutData]);

  useEffect(() => {
    if (removeDiscountCode.isSuccess) {
      toast({
        title: t('success'),
        description: t('discountCodeRemovedSuccessfully')
      });

      return;
    }

    if (removeDiscountCode.isError) {
      toast({
        title: t('error'),
        description: t('anErrorOccurredWhileRemovingTheDiscountCode')
      });

      return;
    }
  }, [removeDiscountCode.isError, removeDiscountCode.isSuccess, t, toast]);

  if(!checkoutData) {
    return null;
  }

  return (
    <div className={props.className}>
      {!checkoutData.merchant_reference2 && (
        <Card className={cn('')}>
          <CardHeader>
            <CardTitle>{t('applyYourDiscountCode')}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className={'flex justify-between gap-10'}>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onApplyCodeSubmit)} className="w-2/3 space-y-6">
                  <FormField
                    control={form.control}
                    name="code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel></FormLabel>
                        <FormControl>
                          <Input placeholder={t('code')}  {...field}/>
                        </FormControl>
                        <FormMessage/>
                      </FormItem>
                    )}
                  />

                  <Button
                    disabled={applyDiscountCode.isPending}
                    type="submit"
                    className={'capitalize'}
                  >
                    {applyDiscountCode.isPending && (
                      <LoadingSpinner className={'mr-2 !w-4 !h-4'}/>
                    )}
                    {t('apply')}
                  </Button>
                </form>
              </Form>
            </div>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      )}

      {checkoutData.merchant_reference2 && (
        <Card>
          <CardHeader>
            <CardTitle>{t('yourDiscountCodeIs')}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className={'flex justify-between gap-10'}>
              <p>{checkoutData.merchant_reference2}</p>
              <Button
                disabled={removeDiscountCode.isPending}
                variant={'destructive'}
                onClick={onRemoveCodeSubmit}
              >
                {removeDiscountCode.isPending && (
                  <LoadingSpinner className={'mr-2 !w-4 !h-4'}/>
                )}
                {t('remove')}</Button>
            </div>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      )}
    </div>
  );
};
