import { MerchantData, SubscriptionInterval } from '@/core/entities';

export const getNextOrderData = (merchantData: MerchantData | null) => {
  if (!merchantData) {
    return null;
  }
  const duration = merchantData.subscriptionInterval
  if (!duration) {
    return null;
  }
  
  switch (duration) {
    case SubscriptionInterval.TWO_WEEKS:
      return twoWeeksFromNow();
    case SubscriptionInterval.FOUR_WEEKS:
      return fourWeeksFromNow();
    case SubscriptionInterval.SIX_WEEKS:
      return sixWeeksFromNow();
    case SubscriptionInterval.EIGHT_WEEKS:
      return eightWeeksFromNow();
    default:
      return null;
  }
};

const twoWeeksFromNow = () => {
  const date = new Date();
  date.setDate(date.getDate() + 14);
  return date;
};

const fourWeeksFromNow = () => {
  const date = new Date();
  date.setDate(date.getDate() + 28);
  return date;
};
const sixWeeksFromNow = () => {
  const date = new Date();
  date.setDate(date.getDate() + 42);
  return date;
};

const eightWeeksFromNow = () => {
  const date = new Date();
  date.setDate(date.getDate() + 56);
  return date;
};
