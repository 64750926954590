import { BackendService, ServiceHost } from "@/core";
import { backendService } from "@/services/klarna/backend-service.ts";
import { discountCodeService } from '@/services/discount-code/discount-code-service.ts';
import { DiscountCodeService } from '@/core/ports/services/discount-code-service.ts';

/**
 * Service host
 *
 * @export
 * @interface ServiceHost
 */
export const serviceHost: ServiceHost = {
  getBackendService(): BackendService {
    return backendService;
  },
  getDiscountCodeService(): DiscountCodeService {
    return discountCodeService;
  }
};
